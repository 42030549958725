export const locations = {
    // 宿舍
    '宿舍-1号楼': [120.707893,30.742808],
    '宿舍-2号楼': [120.707408,30.742679],
    '宿舍-3号楼': [120.706505,30.742567],
    '宿舍-4号楼': [120.70643,30.742905],
    '宿舍-5号楼': [120.706127,30.742515],
        '菜鸟驿站':[120.705928,30.742384],
    '宿舍-6号楼': [120.70606,30.742747],
    '宿舍-7号楼': [120.705949,30.743202],
    '宿舍-8号楼': [120.705922,30.743536],
    '宿舍-9号楼': [120.705928,30.743872],
    '宿舍-10号楼': [120.705858,30.744215],
    '宿舍-11号楼': [120.706254,30.743893],
    '宿舍-12号楼': [120.70621,30.744258],
    '宿舍-13号楼': [120.706921,30.744335],
    '宿舍-15号楼': [120.70886,30.744068],
        '品味江南超市': [120.708934,30.743958],
        '良库咖啡': [120.708934,30.743958],
        '美丽家族': [120.708934,30.743958],
    '宿舍-16号楼': [120.708798,30.744384],
        '茶百道': [120.708798,30.744384],
    "明德书院": [120.708367,30.743875],
        "明德餐厅": [120.708367,30.743875],
    "崇德书院":[120.710085,30.74412],
        "崇德餐厅": [120.710085,30.74412],
    // 食堂
    '1号食堂': [120.707323,30.74332],
    '2号食堂': [120.707323,30.74332],
    '3号食堂': [120.707246,30.743706],
    '4号食堂': [120.707246,30.743706],
    // 教学楼
    '1号楼-行政楼':[120.713776,30.744586],
    '2号楼-图书馆':[120.713101,30.743407],
        '2号楼-报告厅':[120.712944,30.744115],
    '3号楼':[120.712569,30.74164],
    '4号楼':[120.712433,30.742052],
    '5号楼':[120.712427,30.742442],
    '6号楼-文化与旅游学院':[120.712388,30.742831],
    '7号楼-现代农业学院':[120.709604,30.742186],
        '7号楼-城市建设学院':[120.709604,30.742186],
    '8号楼':[120.709623,30.742553],
    '9号楼':[120.709494,30.742954],
    '10号楼':[120.709423,30.743376],
    '11号楼-逸夫楼':[120.709423,30.743376],
        '11号楼-航空与轨道学院':[120.709423,30.743376],
        '11号楼-智能智造学院':[120.709423,30.743376],
    '12号楼-时尚设计学院':[120.716652,30.742248],
    '13号楼-互联网(网络空间安全学院)':[120.716646,30.742645],
    '14号楼-现代商贸学院':[120.716583,30.743064],
    '15号楼':[120.715497,30.742626],
        '神农厅':[120.715497,30.742626],
    '16号楼-实训中心':[120.715395,30.745041],
    '17号楼-教工食堂':[120.716125,30.745077],
    '17号楼-招待所':[120.716125,30.745077],
    //体育
    '羽毛球馆':[120.705942,30.741856],
    '健身房':[120.706006,30.741505],
    '乒乓球馆-2楼':[120.706006,30.741505],
    '地掷球馆':[120.706216,30.740704],
    '篮球场-室外':[120.706076,30.74111],
    '篮球场-室内':[120.707626,30.741861],
    '足球场':[120.706664,30.741455],
    '网球场':[120.707871,30.740849],
    '网球馆':[120.708218,30.742185],
    '排球场':[120.706967,30.740534],
    // 其他
    '初心广场':[120.713287,30.742361],
    '东门':[120.716673,30.744708],
    '南门':[120.713454,30.741215],
};

export const locationNames = Object.keys(locations);
