<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
}
</script> 

<style lang="less" scoped>
#app {
  width: 100vw;
  height: 100vh;
}
</style>
