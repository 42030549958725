<template>
    <div id="index_container">
      <div id="search_wrap">
            <search></search>
        </div>
        <div id="map_wrap">
            <map-component></map-component>
        </div>
    </div>
  </template>
  
  <script>
  import MapComponent from "@/components/MapContainer/MapContainer.vue";
  import Search from "@/components/Search/Search.vue"
  export default {
    components: {MapComponent,Search}}
  </script>
  
  <style lang="less" scoped>
  #index_container {
    height: 100%;
    width: 100%;
    position: relative;
    #map_wrap {
       position: absolute;
       top: 50%;
       left: 50%;
       transform: translate(-50%,-50%);
      width: 100%;
      height:100%;
      z-index: 1;
    }
  }
  #search_wrap {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 70vw;
    height: 6vh;
    z-index: 2;
  }
  </style>