<template>
    <div id="search_container" v-show="showInput">
        <el-input placeholder="请输入关键词搜索" v-model="inputObject.userInput" :id="inputObject.inputId"
            @input="filterResults" suffix-icon="el-icon-search"></el-input>

        <ul v-if="inputObject.userInput && filteredResults.length > 0" class="results-list">
            <li v-for="(result, index) in filteredResults" :key="index" @click="selectResult(result)">
                {{ result }}
            </li>
        </ul>
    </div>
</template>

<script>
import bus from '@/eventBus/eventBus'
import { locations, locationNames } from '@/components/js/locations';
export default {
    data() {
        return {
            showInput: true,
            inputObject: {
                userInput: '',
                inputId: 'searchInput'
            },
            results: locationNames,
            filteredResults: []
        }
    },
    methods: {
        filterResults() {
            const query = this.inputObject.userInput.toLowerCase();
            this.filteredResults = this.results.filter(item =>
                item.toLowerCase().includes(query)
            );
        },
        selectResult(result) {
            this.inputObject.userInput = result;
            this.filteredResults = [];
            bus.$emit('shareUserInput', result); // 发送选择的地点
        },
    },
    created() {
        bus.$on('showInput', value => {
            this.showInput = value;
        });
    }
}
</script>

<style lang="less" scoped>
#search_container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .el-input {
        flex: 1;
    }

    .results-list {
        position: absolute;
        top: calc(100%);
        left: 0;
        width: 99.5%;
        background: white;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        z-index: 1000;
        list-style-type: none;
        padding: 0;
        margin: 0;
        max-height: 300px;
        overflow: auto;

        li {
            padding: 10px;
            cursor: pointer;

            &:hover {
                background-color: #f5f5f5;
            }
        }
    }
}
</style>
