import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router';
import store from './store';
import "@/css/global.css";
import AMapLoader from '@amap/amap-jsapi-loader';

Vue.use(ElementUI);

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '嘉职百晓生导航版地图'; // 设置页面标题为路由的meta中定义的标题，如果没有则使用默认标题
  next()
})

AMapLoader.load({
  key: "b4cb8ec91acfba9c5d814d7783a1f968",
  version: "2.0",
  plugins: ['AMap.Scale', 'AMap.Geolocation', 'AMap.Driving'],
}).then((AMap) => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
});

Vue.config.productionTip = false;
