<template>
    <!-- 地图容器 -->
    <div id="container">
        <!-- 信息面板路径规划 -->
        <div class="pathPlanningPanel" v-show="isPanelVisible">
            <div class="distance-Group">
                <p id="distance">{{ drivingInfo }}</p> <!-- 距离显示 -->
                <div class="button-Group">
                    <img class="arrow-button" :class="{isFlipped:isInfoVisible}" src="../img/arrow.png" alt="显示\关闭" @click="detailedPanel">
                    <img class="close-button" src="../img/close.png" alt="关闭" @click="toggleInfoPanel">
                </div>
            </div>
            <div id="info-content" v-show="isInfoVisible">
                <!-- 这里可以放置路径规划的详细信息 -->
            </div>
        </div>
    </div>

</template>

<script>
import bus from '@/eventBus/eventBus.js'; // 引入事件总线
import { locations, locationNames } from '@/components/js/locations';

// 高德地图安全配置
window._AMapSecurityConfig = {
    securityJsCode: 'fc4b7352cae4637cf1456aefe20b0346' // 安全密钥
}

export default {
    data() {
        return {
            map: null, // 地图实例
            drivingInfo: '路径信息将显示在这里', // 路径信息初始化
            currentPosition: null, // 当前定位
            isPanelVisible: false, // 面板是否可见
            isInfoVisible: false,// 线路规划详细是否可见
            driving: null, // 驾驶路线对象
            center: [120.711168, 30.742777]// 地图中心点
        }
    },
    methods: {
        // 切换信息面板的显示状态 关闭按钮
        toggleInfoPanel() {
            this.isPanelVisible = !this.isPanelVisible;
            bus.$emit('showInput', !this.isPanelVisible); // 触发事件，控制搜索框的显示
            if (this.driving) {
                this.driving.clear(); // 清除上次的路线
            }
        },
        // 切换信息面板的显示状态 箭头
        detailedPanel(){
            this.isInfoVisible = !this.isInfoVisible
        },

        // 初始化地图
        initMap() {
            this.map = new AMap.Map("container", {
                viewMode: "3D", // 3D视图模式
                zoom: 17, // 初始缩放级别
                center: this.center,// 地图中心点
                features: ['bg', 'road', 'building'] // 显示的地图要素
            });

            // 添加定位控制
            const geolocation = new AMap.Geolocation({
                enableHighAccuracy: true,
                timeout: 10000,
                position: 'RB',
                buttonOffset: new AMap.Pixel(10, 20),
                zoomToAccuracy: true,
                showMarker: true,
                showCircle: true,
                panToLocation: true,
                useNative: true,
            });
            this.map.addControl(geolocation);

            // 获取当前位置
            geolocation.getCurrentPosition((status, result) => {
                if (status === 'complete') {
                    this.currentPosition = {
                        latitude: result.position.lat,
                        longitude: result.position.lng
                    };
                } else {
                    console.log('定位失败', result);
                    this.$message.warning('初始化失败，请刷新页面重试');
                }
            });

            // 添加图层
            const imageLayer = new AMap.ImageLayer({
                url: require('../img/map.png'), // 图片路径
                bounds: new AMap.Bounds(
                    [120.704632, 30.73994],
                    [120.717803, 30.74544]
                ),
                opacity: 1,
                zooms: [3, 20],
                zIndex: 10,
            });
            this.map.add(imageLayer);
        },

        // 开始路径规划
        startRouting(destination) {
            if (!this.currentPosition) {
                this.$message.error("当前定位无效，请重试");
                return;
            }

            const startPoint = [this.currentPosition.longitude, this.currentPosition.latitude]; // 起点
            const endPoint = this.getLocationByName(destination); // 终点

            if (!endPoint) {
                this.$message.error("目的地无效");
                return;
            }

            this.drivingInfo = '路径信息将显示在这里'; // 重置路径信息
            this.isPanelVisible = true; // 确保每次都显示面板
            this.isInfoVisible = true;

            if (this.driving) {
                this.driving.clear(); // 清除上次的路线
            }

            this.driving = new AMap.Driving({
                map: this.map,
                panel: "info-content" // 路线信息显示区域
            });

            this.$nextTick(() => {
                this.driving.search(startPoint, endPoint, (status, result) => {
                    if (status === 'complete') {
                        this.drivingInfo = `路径规划成功：${result.routes[0].distance} 米`; // 更新路径信息
                        this.$message.success(`路径规划成功`);
                        console.log(result);
                        bus.$emit('showInput', false); // 隐藏搜索框
                    } else {
                        this.drivingInfo = '路径规划失败';
                        this.$message.error('路径规划失败');
                    }
                });
            });
        },

        // 根据地点名称获取坐标
        getLocationByName(name) {
            return locations[name];
        }
    },
    created() {
        // 监听事件
        bus.$on('shareUserInput', val => {
            this.startRouting(val); // 根据选择的地点进行路径规划
        });
    },
    mounted() {
        this.initMap(); // 初始化地图   
    },
}
</script>

<style lang="less" scoped>
// 地图容器
#container {
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
    position: relative; // 设定相对定位
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

// 路径规划面板
.pathPlanningPanel {
    width: 90%;
    max-height: 30%;
    margin-top: 2vh;
    position: relative;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    padding: 10px;
    // 毛玻璃效果
    background: rgba(255, 255, 255, 0.5);
    /* 半透明背景 */
    backdrop-filter: blur(10px);
    /* 毛玻璃效果，设置模糊程度 */

    border-radius: 4px; // 圆角
}

// 距离显示容器
.distance-Group {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.button-Group{

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-between
    
}
.arrow-button{
    width: 24px;
    height: 16px;
    margin-right: 3vw;
}
.isFlipped{
    transform: rotateX(180deg);  
  /* 如果需要，可以添加过渡效果 */  

}
// 关闭按钮
.close-button {
    width: 35px;
    height: 35px;
}

#info-content {
    width: 100%;
    overflow: auto;
}
</style>
